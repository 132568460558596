/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ubytování a pronájem"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column style={{"paddingBottom":28,"paddingTop":102,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"ubytovaniapronajem"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ubytování a pronájem na zámku Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--20" style={{"maxWidth":1122,"marginTop":58}} content={"<span style=\"color: var(--color-custom-1);\">Zámek Vidžín nabízí jedinečné zázemí pro svatby, firemní i kulturní akce s možností ubytování přímo v zámeckém areálu.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" style={{"paddingTop":83,"paddingBottom":81}} name={"pronajem"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":1462}} columns={"4"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":304}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(87, 59, 143); font-weight: bold;\">Pronájem</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":650,"marginTop":50}} content={"<span style=\"color: var(--color-custom-1);\">Zámek Vidžín lze pronajmout jako celek nebo jen část. K pronajmutí jsou tyto prostory:</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":22}} content={"<span style=\"font-weight: bold;\">1)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":10}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">velký zastřešený altán</span> se stoly a lavicemi</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":26}} content={"<span style=\"font-weight: bold;\">2)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":9}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">menší altán s grilem</span> a s posezením</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":294}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">3)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":650,"marginTop":10}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">velký salónek</span> pro uzavřenou společnost a hostiny</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":18}} content={"<span style=\"font-weight: bold;\">4)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":12}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">restaurace</span> vnitřní i venkovní část</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":21}} content={"<span style=\"font-weight: bold;\">5)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":8}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">kuchyně</span> s profesionálním vybavením (2 konvektomaty, apod.)</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":17}} content={"<span style=\"font-weight: bold;\">6)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":8}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">podium pro hudebníky</span>&nbsp;a možnost zapůjčení aparatury</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":21}} content={"<span style=\"font-weight: bold;\">7)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":9}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">taneční parket</span></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":294}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">8)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":650,"marginTop":10}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">zpevněné plochy</span> v areálu zámku vhodné pro výstavy (například automobilů, motorek, řemeslných stánků, atrakcí, ...)</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":25}} content={"<span style=\"font-weight: bold;\">9)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":12}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">parkovací plochy</span>\n(až 40 aut nebo 4 autobusy)</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":34}} content={"<span style=\"font-weight: bold;\">10)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":8}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">zámecká zahrada</span> (pro obřady, focení, zahradní slavnosti, ...)</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":26}} content={"<span style=\"font-weight: bold;\">11)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":8}} content={"<span style=\"color: var(--color-custom-1);\">přilehlý <span style=\"font-weight: bold;\">zámecký park</span></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":294}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">12)</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--20" style={{"maxWidth":650,"marginTop":10}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">zámecké prostory</span> jako barokní sál, přípravna, herna pro děti, herna pro batolata, pánský salón s kulečníkem a fotbálkem, ...&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":18}} content={"<span style=\"font-weight: bold;\">13)</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--20" style={{"marginTop":12}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">chatka s herními stoly</span> na ping pong</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" style={{"paddingTop":62,"paddingBottom":68}} name={"ubytovani"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":1462}} columns={"4"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":304}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(87, 59, 143); font-weight: bold;\">Ubytování</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":650,"marginTop":50}} content={"<span style=\"color: var(--color-custom-1);\">Hosté se mohou ubytovat buď v historické zámecké budově, nebo v samostatných chatkách umístěných v klidném prostředí zámeckého parku.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":294}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">14)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":650,"marginTop":10}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: 700;\">pronájem chatek</span> v zámeckém parku.&nbsp;Chatky jsou ideální pro pobyty větších skupin, účastníky akcí a svatebčanů.&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":12}} content={"<span style=\"font-weight: bold;\">15)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":12}} content={"<span style=\"color: rgb(0, 0, 0); font-weight: 700;\">3 zámecké ložnice</span><span style=\"color: rgb(0, 0, 0);\"> ve stylu vídeňského baroka</span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":15}} content={"<span style=\"font-weight: bold;\">16)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":8}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">16 pokojíčků</span> v horním patře zámku</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":294}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">17)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":650,"marginTop":10}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">pronájem celku</span>, celková kapacita ubytování na zámku činí <span style=\"font-weight: bold;\">až 200 osob</span></span>"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":35}} content={"<span style=\"font-weight: bold;\">18)</span>"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"marginTop":15}} content={"<span style=\"color: var(--color-custom-1);\">V případě potřeby lze umístit stany do parkové části <br>z důvodu navýšení ubytovací kapacity.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":294}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":54,"backgroundColor":"#e8e5fa","marginBottom":0}} name={"pronajmycena"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20 flex--top" style={{"maxWidth":1620,"backgroundColor":"rgba(232,229,250,1)","paddingTop":22}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Cena za pronájem na celý víkend"}>
              </Title>

              <Subtitle className="subtitle-box ff--1 fs--26 w--300" style={{"marginTop":31}} content={"(cenový příklad pronájmu všech výše uvedených položek za nájem i ubytování na celý víkend)"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0 pt--0" style={{"paddingBottom":26}}>
              
              <Subtitle className="subtitle-box fs--43 w--400 ls--04 lh--12" content={"<span style=\"font-weight: bold;\">100.000,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 ff--3 fs--20" style={{"backgroundColor":"rgba(87,59,143,1)","marginBottom":24,"marginTop":61}} content={"Váháte? Zavolejte, domluvíme se."} use={"tel"} href={"tel:+420602625691"} url={"tel:+420602625691"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"paddingBottom":86,"paddingTop":56}} name={"pronajmygalerie1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/d6f862e4a4e7483e80ff01fb7f04ed25_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/d6f862e4a4e7483e80ff01fb7f04ed25_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/d6f862e4a4e7483e80ff01fb7f04ed25_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/d6f862e4a4e7483e80ff01fb7f04ed25_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/d6f862e4a4e7483e80ff01fb7f04ed25_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/d6f862e4a4e7483e80ff01fb7f04ed25_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/2ec5fba3cbf44e4b8359df23e3edfd83_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/2ec5fba3cbf44e4b8359df23e3edfd83_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/2ec5fba3cbf44e4b8359df23e3edfd83_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/2ec5fba3cbf44e4b8359df23e3edfd83_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/2ec5fba3cbf44e4b8359df23e3edfd83_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/125fac9cb62b4a858b43df2e6922ec34_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/125fac9cb62b4a858b43df2e6922ec34_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/125fac9cb62b4a858b43df2e6922ec34_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/125fac9cb62b4a858b43df2e6922ec34_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/bc49630d15a14e81aca4797645c209e0_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/bc49630d15a14e81aca4797645c209e0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/bc49630d15a14e81aca4797645c209e0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/bc49630d15a14e81aca4797645c209e0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/bc49630d15a14e81aca4797645c209e0_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/8c93dd72dc9b4f4787110c706f984fea_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/8c93dd72dc9b4f4787110c706f984fea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/8c93dd72dc9b4f4787110c706f984fea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/8c93dd72dc9b4f4787110c706f984fea_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/925f1cda339b43caadf549a2a2ebd581_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/925f1cda339b43caadf549a2a2ebd581_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/925f1cda339b43caadf549a2a2ebd581_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/925f1cda339b43caadf549a2a2ebd581_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/925f1cda339b43caadf549a2a2ebd581_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":87}} layout={"l9"} name={"pronajmygalerie2"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/cda43cfbf0d94836bb10f0c7dd2e9ae6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/cda43cfbf0d94836bb10f0c7dd2e9ae6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/cda43cfbf0d94836bb10f0c7dd2e9ae6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/cda43cfbf0d94836bb10f0c7dd2e9ae6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/cda43cfbf0d94836bb10f0c7dd2e9ae6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/cda43cfbf0d94836bb10f0c7dd2e9ae6_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left fs--20 pl--0" style={{"maxWidth":1165}} content={"<span style=\"color: var(--color-custom-1);\">Prostor pro zvukovou aparaturu v zadní části zámku je vhodný pro koncerty, malá divadelní představení, různá kulturní vystoupení, oslavy či slavnosti s hudbou, ... Poskytuje i prostor pro tanec a příjemné posezení u zámeckého parku.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#dbd6f3","paddingTop":51,"paddingBottom":46}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" style={{"marginTop":0}} content={"Odkazy"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":334,"marginTop":30}} content={"<a href=\"/nf-vidzin#nfvidzinuvod\" style=\"color: var(--color-custom-1);\">Nadační fond Vidžín</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--22" style={{"marginTop":19}} content={"<a href=\"/#prohlidky\" style=\"color: var(--color-custom-1);\">Prohlídky zámku</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"Zámek Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":311,"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín\n<br>Vidžín č.8, 330 40 Úterý<br><span style=\"font-weight: bold;\">tel:&nbsp;602 625 691</span></span>"}>
              </Text>

              <Image className="--center" style={{"maxWidth":196,"marginTop":27,"paddingLeft":33}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=1400x_.png 1400w"} alt={"logo zámek Vidžín"} src={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"marginTop":0}} content={"Sociální sítě"}>
              </Title>

              <Image style={{"maxWidth":40,"paddingLeft":0,"marginTop":31}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png 350w"} alt={"facebook"} src={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.facebook.com/zameckafaravidzin"} url={"https://www.facebook.com/zameckafaravidzin"}>
              </Image>

              <Image style={{"maxWidth":38,"paddingLeft":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/zamekvidzin/"} url={"https://www.instagram.com/zamekvidzin/"}>
              </Image>

              <Image style={{"maxWidth":42}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.youtube.com/watch?v=aIwoNcyrEC4"} url={"https://www.youtube.com/watch?v=aIwoNcyrEC4"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}